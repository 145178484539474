import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

//styles
import s from "./FooterBottom.module.scss";
import breakpoints from "../../../../styles/vars";

//ui
import Contaiter from "../../../../components/ui/Containers/container/Contaiter";
import ContainerFooter from "../../../ui/Containers/containerFooter/ContainerFooter";

//db
import { paytmentsMethod } from "../../../../db/generals/generalsData";
import { soicialsLink } from "../../../../db/generals/generalsData";

const FooterBottom: React.FC = () => {
  const { t } = useTranslation();

  const lg = useMediaQuery({ maxWidth: breakpoints.lg });

  const newDate = new Date();

  return (
    <div className={s.root}>
      <Contaiter>
        <ContainerFooter>
          <div className={s.wrapper}>
            <div className={s.inner}>
              <div className={s.paytment}>
                {paytmentsMethod?.length > 0 &&
                  paytmentsMethod.map((item, index: number) => {
                    return (
                      <img
                        key={index}
                        src={item.svg}
                        className={s.paytmentMethod}
                        alt={"paytment method"}
                      />
                    );
                  })}
              </div>
              {lg && (
                <div className={s.socialsWrapper}>
                  {soicialsLink?.length > 0 &&
                    soicialsLink.map((soicialLink, index: number) => {
                      return (
                        <a
                          href={soicialLink.src}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                          className={s.socialLink}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: soicialLink.svg,
                            }}
                            className={s.socialSvg}
                          />
                        </a>
                      );
                    })}
                </div>
              )}
            </div>
            {lg && (
              <div className={s.savingRights}>
                © {newDate.getFullYear()}
                <span>{t("footer.copyright")}</span>
              </div>
            )}
          </div>
        </ContainerFooter>
      </Contaiter>
    </div>
  );
};

export default FooterBottom;
